import React from 'react';
import '../index.css';
import '../styles/AboutPage.css';
import InstantFlowsLogoSub from '../components/logos/InstantFlowsLogo - subtitle.png';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

import Jorrit from '../components/team/Jorrit_profile.png';
import Odylia from '../components/team/Odylia_profile.png';
import George from '../components/team/George_profile.png';
import AnnaMaria from '../components/team/Annamaria_profile.png';
import Priscille from '../components/team/Priscille_profile.png';
import Dennis from '../components/team/Dennis_profile.png';
import Bram from '../components/team/Bram_profile.png';
import Nicolas from '../components/team/Nicolas_profile.png';
import Cas from '../components/team/Cas_profile.jpg';
import Miguel from '../components/team/Miguel_profile.jpg';


function AboutPage() {

  const{t, i18n} = useTranslation() ;

    useEffect(() => {
      i18n.changeLanguage(navigator.language);
    }, []);


  return (
    <div className="about-page">
      <div className="hero hero-1 top-hero">
        <h1>About Us</h1>
        <div className="content-grid-1">
          <div className="container">
            <p>InstantFlows is an AI solutions company dedicated to helping you save time, cut costs, and elevate your decision-making. We understand the concerns around AI, such as data privacy, fairness, job security, and transparency. That's why we've created our SAFETY values to ensure we use AI responsibly, ethically, and in a way that benefits everyone.</p>
          </div>
          <div className="container">
            <h3>Safety First</h3>
            <p>Protecting your data, protecting your trust. We take every step to keep your data safe, secure, and only used in ways you agree to. You'll always know what happens with your information—no surprises, just trust.</p>
          </div>
          <div className="container">
            <h3>Assist, Don't Replace</h3>
            <p>AI to help people, not replace them. We build tools that make your work easier, faster, and better—not tools that take over your job. Our goal is to save you time and let you focus on what humans do best: thinking creatively, solving problems, and building relationships.</p>
          </div>
          <div className="container">
            <h3>Fair and Equal</h3>
            <p>AI that treats everyone fairly. AI should work for everyone, not just a few. We work hard to make sure our tools don't carry bias or unfairness. We believe everyone deserves the same opportunities, and our AI reflects that belief.</p>
          </div>
          <div className="container">
            <h3>Easy to Understand</h3>
            <p>AI that's clear and honest. Some AI feels like a mystery, making decisions that are hard to explain. Not ours. We make sure you can understand how and why our tools work so you can trust the results. No hidden tricks, just clear answers.</p>
          </div>
          <div className="container">
            <h3>Transparency</h3>
            <p>Nothing hidden, everything clear. We believe transparency is key to building trust. From how we develop our tools to how they function, we're open and honest every step of the way.</p>
          </div>
          <div className="container">
            <h3>You at the Center</h3>
            <p>Our top priority is you. Everything we do is built around your needs. AI is a tool to make your life easier, your work better, and your goals achievable. At InstantFlows, you are—and always will be—our main focus.</p>
          </div>
        </div>
        </div>

      <div className="team-section">
        <h2>Meet Our Team</h2>
          <div className="team-images">
            <img src={Jorrit} alt="Jorrit" />
            <img src={Odylia} alt="Odylia" />
            <img src={George} alt="George" />
            <img src={AnnaMaria} alt="AnnaMaria" />
            <img src={Priscille} alt="Priscille" />
            <img src={Dennis} alt="Dennis" />
            <img src={Bram} alt="Bram" />
            <img src={Nicolas} alt="Nicolas" />
            <img src={Cas} alt="Cas" />
            <img src={Miguel} alt="Miguel" />
          </div>
        <div className="careers-link">
          <h3>Join Our Team</h3>
          <p>Interested in working with us? Check out our career opportunities.</p>
          <a href="/careers">Careers</a>
        </div>
      </div>

      <div className="hero hero-1">      
          <h2>How the Instant Research Service Works</h2> 
          <div className="textbox">
            <h3>1. Create Your Research Topic:</h3> 
            <p>Start by defining what you want to research - this could be a product, service, or concept you want to understand better.</p>
          </div>
          <div className="textbox">
            <h3>2. Design Your Survey:</h3> 
            <p>Create your research questions with our flexible question builder. Choose from open-ended, multiple choice, or true/false questions to gather the insights you need.</p>
          </div>
          <div className="textbox">
            <h3>3. Define Your Target Audience:</h3> 
            <p>Use our demographic filters to specify your ideal respondents - including age range, location, and income level. Our AI will generate detailed personas matching your criteria.</p>
          </div>
          <div className="textbox">
            <h3>4. Get AI-Generated Responses:</h3> 
            <p>Our AI simulates responses from your created personas, providing realistic feedback based on their demographic profiles and characteristics.</p>
          </div>
          <div className="textbox">
            <h3>5. Analyze Results:</h3> 
            <p>Review responses across all your personas and use our AI analysis tools to uncover key insights and patterns in your research data.</p>
          </div>
      </div>
        
    </div>
  );
}

export default AboutPage;
