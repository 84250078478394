import '../styles/ResearchPage.css';
import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../authentication/AuthContext';

import TabContent from './TabContent';
import { 
  topicActions, questionActions, personaActions, loadResearchData, updateTopicInBackend
} from './ResearchActions';
import { researchApi } from '../services/researchApi';
import trashBin from '../components/icons/trash_bin.png';
import { MAX_VISIBLE_PERSONAS } from './ResearchConstants';

function ResearchPage() {
  const { updateUserAttributes, credits, user, sub} = useAuth();
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Questions');
  const [newTopicName, setNewTopicName] = useState('');
  const [personas, setPersonas] = useState([]);
  const [visibleCount, setVisibleCount] = useState(MAX_VISIBLE_PERSONAS);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [isPremiumResearch, setIsPremiumResearch] = useState(false);
  const [researchDescription, setResearchDescription] = useState('');
  const [mainResearchQuestion, setMainResearchQuestion] = useState('');
  const [researchType, setResearchType] = useState('BASIC');
  const topicInputRef = useRef(null);

  user.id = sub // TODO: quick fix as user.id was undefined, need to solve properly

  // Load initial research data when component mounts
  useEffect(() => {
    if (sub) {
      loadResearchData(sub, setTopics)
        .catch(error => {
          console.error('Error loading research data:', error);
        });
    }
  }, [sub]);

  const handleNewResearchClick = () => {
    topicActions.newResearch(setSelectedTopic, setSelectedTab);
    topicInputRef.current?.focus();
    setIsSidebarVisible(false);
  };

  const handleTopicSelect = async (topic) => {
    setSelectedTopic(topic);
    if (topic) {
      // Load personas for selected topic
      try {
        const response = await researchApi.getPersonas(topic.id);
        console.log('Personas response:', response);
        setPersonas(response.data.personas);
      } catch (error) {
        console.error('Failed to load personas:', error);
        setPersonas([]);
      }
    } else {
      setPersonas([]);
    }
  };

  const handleNextTab = () => {
    if (selectedTab === 'Questions') setSelectedTab('Personas');
    else if (selectedTab === 'Personas') setSelectedTab('Responses');
    setIsSidebarVisible(false);
  };

  const handleUpgradeToPremium = async () => {
    const upgradeConfirmed = window.confirm('To add more than 10 questions, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
    if (upgradeConfirmed) {
      updateUserAttributes({ 'custom:credits': credits - 2 });
      
      // Update topics and save
      const updatedTopics = topics.map(t => 
        t.id === selectedTopic.id ? { ...t, isPremium: true } : t
      );
      setTopics(updatedTopics);
      setSelectedTopic(prev => ({ ...prev, isPremium: true }));
      setIsPremiumResearch(true);
      
      // Update backend
      await updateTopicInBackend(selectedTopic, user.id);
      
      // Continue with adding question
      questionActions.addQuestion(selectedTopic, setSelectedTopic, topics, setTopics);
    }
  };

  const handlePersonaUpgrade = async () => {
    const upgradeConfirmed = window.confirm('To have more than 100 personas, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
    if (upgradeConfirmed) {
      updateUserAttributes({ 'custom:credits': credits - 2 });
      
      // Update topics and save
      const updatedTopics = topics.map(t => 
        t.id === selectedTopic.id ? { ...t, isPremium: true } : t
      );
      setTopics(updatedTopics);
      setSelectedTopic(prev => ({ ...prev, isPremium: true }));
      setIsPremiumResearch(true);
      
      // Update backend
      await updateTopicInBackend(selectedTopic, user.id);
      
      // Continue with adding persona
      personaActions.addPersona(personas, setPersonas, selectedTopic, setTopics, topics);
    }
  };

  const handleAIUpgrade = async () => {
    const upgradeConfirmed = window.confirm('To analyze with AI, you need to upgrade this research to a premium research. This will cost an additional 2 credits. Do you want to proceed?');
    if (upgradeConfirmed) {
      updateUserAttributes({ 'custom:credits': credits - 2 });
      
      // Update topics and save  
      const updatedTopics = topics.map(t => 
        t.id === selectedTopic.id ? { ...t, isPremium: true } : t
      );
      setTopics(updatedTopics);
      setSelectedTopic(prev => ({ ...prev, isPremium: true }));
      setIsPremiumResearch(true);
      
      // Update backend
      await updateTopicInBackend(selectedTopic, user.id);
      
      // Continue with AI analysis
      // ... AI analysis code ...
    }
  };

  const handleAddTopic = async () => {
    try {
      const creditCost = researchType === 'BASIC' ? 1 : 3;
      if (credits < creditCost) {
        alert('Insufficient credits to start a new research.');
        return;
      }

      await topicActions.addTopic(
        mainResearchQuestion,
        researchDescription,
        setTopics, 
        setNewTopicName, 
        setSelectedTopic, 
        setSelectedTab,
        researchType === 'PREMIUM',
        user.id
      );
      
      updateUserAttributes({ 'custom:credits': credits - creditCost });
      setIsSidebarVisible(false);
    } catch (error) {
      console.error('Error creating topic:', error);
      alert('Failed to create research. Please try again.');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && newTopicName.trim()) {
      handleAddTopic();
    }
  };

  const TopicList = ({ topics, onTopicClick, onTopicDelete }) => (
    <div className="topic-list">
      {topics.length === 0 ? (
        <div className="textbox container">Loading topics...</div>
      ) : (
        <ul>
          {topics.map((topic) => (
            <li 
              key={topic.id} 
              onClick={() => onTopicClick(topic)}
              className={topic.isPremium ? 'premium-topic' : ''}
            >
              <span>{topic.name}</span>
              <div className="topic-actions">
                {topic.isPremium && <span className="premium-star">★</span>}
                <img 
                  src={trashBin} 
                  alt="Delete" 
                  onClick={(e) => {
                    e.stopPropagation();
                    onTopicDelete(topic.id);
                  }} 
                  className="click-icon" 
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  return (
    <div className="research-page">
      <aside className={`sidebar ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
        <div className="sidebar-header">
          <h2>Your Research Topics</h2>
        </div>
        <button className="button standout-button research-button" style={{ maxWidth: 'none', width: '90%' }} onClick={handleNewResearchClick}>
          New Research
        </button>
        <TopicList
          topics={topics}
          selectedTopic={selectedTopic}
          newTopicName={newTopicName}
          setNewTopicName={setNewTopicName}
          onTopicClick={handleTopicSelect}
          onAddTopic={handleAddTopic}
          onTopicDelete={(topicId) => topicActions.deleteTopic(topicId, topics, setTopics, setSelectedTopic)}
        />
      </aside>

      <button 
        className="toggle-sidebar"
        onClick={() => setIsSidebarVisible(!isSidebarVisible)}
      >
        {isSidebarVisible ? '←' : '→'}
      </button>

      <main className={`research-content ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
      {selectedTopic ? (
        <>
        <h2>{selectedTopic.name} - {selectedTopic.isPremium ? 'Premium' : 'Basic'}</h2>
        <div className="tabs">
          {['Questions', 'Personas', 'Responses'].map(tab => (
          <button
          key={tab}
          className={`tab ${selectedTab === tab ? 'active' : ''}`}
          onClick={() => {
            setSelectedTab(tab);
            setIsSidebarVisible(false);
          }}
          >
            {tab}
          </button>
          ))}
        </div>
        <div className="tab-content">
          <TabContent
            selectedTab={selectedTab}
            selectedTopic={selectedTopic}
            // onQuestionEdit={handleQuestionEdit} TODO: uncomment when implemented
            onQuestionDelete={(questionId) => 
              questionActions.deleteQuestion(questionId, selectedTopic, setSelectedTopic, setTopics, user.id)}
            onAddQuestion={() => 
              questionActions.addQuestion(selectedTopic, setSelectedTopic, setTopics, user.id)}
            personas={personas}
            setPersonas={setPersonas}
            // onPersonaEdit={handlePersonaEdit} TODO: uncomment when implemented
            onPersonaDelete={(personaId) => 
              personaActions.deletePersona(personaId, personas, setPersonas, selectedTopic, setTopics, user.id)}
            onAddPersona={() => 
              personaActions.addPersona(personas, setPersonas, selectedTopic, setTopics, user.id)}
            onNextTab={handleNextTab}
            visibleCount={visibleCount}
            setVisibleCount={setVisibleCount}
          />
        </div>
        </>
        ) : (
          
          <div className="container new-topic">
            <h3>What would you like to research?</h3>
            <input
              type="text"
              value={mainResearchQuestion}
              onChange={(e) => setMainResearchQuestion(e.target.value)}
              placeholder="Enter new research question"
            />
            <textarea
              value={researchDescription}
              onChange={(e) => setResearchDescription(e.target.value)}
              placeholder="Add research description"
              rows="4"
              className="research-textarea"
            />
            <select
              value={researchType}
              onChange={(e) => setResearchType(e.target.value)}
            >
              <option value="BASIC">Basic Research (1 credit)</option>
              <option value="PREMIUM">Premium Research (3 credits)</option>
            </select>
            <button
              className="button research-button"
              onClick={handleAddTopic}
              disabled={!mainResearchQuestion.trim() || !researchDescription.trim()}
            >
              Submit
            </button>
          </div>
        )}

      </main>
      </div>
  );
}

export default ResearchPage;
