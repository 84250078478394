import React, { useState, useRef, useEffect } from 'react'; // Added useRef and useEffect
import { useAuth } from '../authentication/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/Header.css';
import InstantFlowsLogo from './logos/InstantFlowsLogo-1.png';
import '../i18n';
import { useTranslation } from 'react-i18next';

function Header() {
  const { user, logout, credits, subscription } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const{t, i18n} = useTranslation() ;

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, []);
    if (window.location.hash.includes('#')) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (window.location.hash === '#top') {
        window.history.replaceState(null, null, window.location.pathname);
      } 
    }

  const menuRef = useRef(null); // Create a ref for the menu
  const toggleRef = useRef(null); // Create a ref for the menu toggle button

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to home on logout
  };

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the menu and the toggle button
      if (
        menuRef.current && !menuRef.current.contains(event.target) &&
        toggleRef.current && !toggleRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef, toggleRef]);


  return (
    <header className="header">
      <div className="header-content">
        <Link to="/#top" className="logo-link">
          <img src={InstantFlowsLogo} alt="InstantFlows Logo" className="logo" />
        </Link>
        <button 
          ref={toggleRef} // Attach the ref to the button
          className={`menu-toggle ${isMenuOpen ? 'open' : ''}`}
          onClick={() => setIsMenuOpen(!isMenuOpen)} 
          aria-expanded={isMenuOpen} 
          aria-label={isMenuOpen ? "Close Menu" : "Open Menu"}
        >
          <span className="menu-icon"></span>
        </button>

        <nav ref={menuRef} className={`overlay-nav ${isMenuOpen ? 'open' : ''}`}>
          <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
            <li><Link to="/about#top">About us</Link></li>
            <li><Link to="/ourpersonas#top">Personas</Link></li>
            <li><Link to="/pricing#top">Pricing</Link></li>
            <li><Link to="/blogs#top">Blogs</Link></li>         
            {user ? (
              <li className="account-menu">
                <div className="auth-buttons-container">
                  <Link to="/research#top" className="auth-button startresearch-button">
                    Start Research
                  </Link>
                  <div className="account-trigger">
                      <Link to="/account#top" className="auth-button login-button">
                        My Account
                      </Link>
                    <div className="account-dropdown">
                      <div className="account-info">
                        <p>Credits: {credits}</p>
                        <p>Plan: {subscription}</p>
                      </div>
                      <div className="dropdown-container">
                        <Link to="/payment#top" className="auth-button startresearch-button" state={{ type: 'credits' }}>
                          Top Up Credits
                        </Link>
                        <Link to="/login#top" className="auth-button login-button" onClick={handleLogout}>
                          Logout
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ) : (
              <li className="auth-buttons-container">
                <Link to="/login#top" className="auth-button login-button">
                  Login
                </Link>
                <Link to="/login#top" className="auth-button startresearch-button" 
                  onClick={() => { alert("You need to be logged in first.")}}>
                  Start Research
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;