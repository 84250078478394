import React, { useState } from 'react';
import '../index.css';
import '../styles/PricingPage.css';
import { useAuth } from '../authentication/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";


function Pricing() {

  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, [i18n]);

  const { user } = useAuth();
  const navigate = useNavigate();
  const [openFaq, setOpenFaq] = useState(null);

  const handleGetStarted = (plan) => {
    if (plan === 'Free') {
      if (user) {
        navigate('/research');
      } else {
        navigate('/login', { state: { redirect: '/research' } });
      }
    } else {
      navigate('/payment', { state: { subscription: plan } });
    }
  };

  const toggleFaq = (index) => {
    setOpenFaq(openFaq === index ? null : index);
  };

  const faqItems = [
    {
      question: "What's included in each plan?",
      answer: "The Free plan includes basic research with up to 10 questions and 100 personas. The Basic plan adds full survey history and minimum 12 weekly credits. The Premium plan includes unlimited questions, unlimited personas, AI analysis, and minimum 21 weekly credits."
    },
    {
      question: "How do credits work?",
      answer: "Credits reset every Sunday to your plan's minimum (12 for Basic, 21 for Premium) or your current balance, whichever is higher. Basic research costs 1 credit (€2.00, or €1.00 for Premium users), while Premium research costs 3 credits (€5.00, or €2.00 for Premium users)."
    },
    {
      question: "What happens to my unused credits?",
      answer: "If you have more credits than your plan's minimum, they'll be preserved during the weekly reset. Credits are only deducted when you start a research task."
    },
    {
      question: "When does my subscription payment occur?",
      answer: "Your subscription is billed monthly from your initial signup date. Credits reset every Sunday at midnight, maintaining the higher of your current balance or plan minimum."
    }
  ];

  return (
    <div className="pricing-page">
      <section className="hero hero-1">
        <h1>Pricing</h1>
        <p>Choose your plan that fits your research needs</p>
      </section>

      <div className="pricing-container">
        <div className="pricing-card">
          <h2>Free</h2>
          <div className="price">
            <span className="amount">€0</span>
            <span className="period">/month</span>
          </div>
          <ul>
            <li>1 Basic Research per week</li>
            <li>Basic visualization</li>
            <li>Limited features</li>
          </ul>
          <div className="button-container">
            <button className="button" onClick={() => handleGetStarted('Free')}>Get Started</button>
          </div>
        </div>

        <div className="pricing-card featured">
          <h2>Basic</h2>
          <div className="price">
            <span className="amount">€25</span>
            <span className="period">/month</span>
          </div>
          <ul>
            <li>12 weekly research credits</li>
            <li>Full survey history</li>
            <li>Basic visualization</li>
          </ul>
          <div className="button-container">
            <button className="button" onClick={() => handleGetStarted('Basic')}>Get Started</button>
          </div>
        </div>

        <div className="pricing-card">
          <h2>Premium</h2>
          <div className="price">
            <span className="amount">€40</span>
            <span className="period">/month</span>
          </div>
          <ul>
            <li>21 weekly research credits</li>
            <li>Advanced AI analysis</li>
            <li>Premium Insight reports</li>
          </ul>
          <div className="button-container">
            <button className="button" onClick={() => handleGetStarted('Premium')}>Get Started</button>
          </div>
      </div>
      </div>

      <section className="hero hero-1 pricing-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-container ">
          {faqItems.map((item, index) => (
            <div key={index} className="faq-item textbox">
              <button 
                className={`faq-question ${openFaq === index ? 'active' : ''}`}
                onClick={() => toggleFaq(index)}
              >
                <h3>{item.question}</h3>
                <span className="faq-icon">{openFaq === index ? '−' : '+'}</span>
              </button>
              <div className={`faq-answer ${openFaq === index ? 'open' : ''}`}>
                <p>{item.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}

export default Pricing;
