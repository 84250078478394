import React, { useState } from 'react';
import '../index.css';
import '../styles/PersonasPage.css';
import { useAuth } from '../authentication/AuthContext';

function OurPersonas() {
  const { user } = useAuth();
  const [expandedCard, setExpandedCard] = useState(null);

  const toggleCard = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
  };

  const personas = [
    {
      name: "Emily",
      title: "Marketing Manager",
      age: 34,
      salary: "€85,000/year",
      image: require('../components/personas/Emily.jpeg'),
      interests: ["Digital Marketing", "Yoga", "Travel Photography"],
      backstory: "With 8 years of experience in digital marketing, Emily leads campaigns for major retail brands. She's passionate about data-driven decision making and building authentic brand voices.",
      challenges: "Balancing creative innovation with ROI metrics",
      goals: ["Increase brand engagement by 25%", "Develop sustainable marketing strategies"]
    },
    {
      name: "James",
      title: "Product Owner",
      age: 39,
      salary: "€115,000/year",
      image: require('../components/personas/James.jpeg'),
      interests: ["Agile Methodologies", "Cycling", "Craft Beer"],
      backstory: "James has over a decade of experience in software development, leading cross-functional teams to deliver innovative products. He excels at translating customer needs into actionable roadmaps and prioritizing features.",
      challenges: "Aligning stakeholder expectations with technical constraints",
      goals: ["Launch a new product line by Q4", "Improve team velocity by 20%"]
    },
    {
      name: "Sophie",
      title: "UX Researcher",
      age: 45,
      salary: "€78,000/year",
      image: require('../components/personas/Sophie.jpeg'),
      interests: ["User Experience Design", "Hiking", "Cooking Classes"],
      backstory: "With a background in psychology and human-computer interaction, Sophie conducts user research to uncover insights that drive design decisions. She's skilled at facilitating workshops and synthesizing qualitative data.",
      challenges: "Balancing user needs with business goals",
      goals: ["Increase user adoption by 30%", "Improve product usability scores"]
    },
    {
      name: "Thomas",
      title: "Digital Strategist",
      age: 36,
      salary: "€95,000/year",
      image: require('../components/personas/Thomas.jpeg'),
      interests: ["Digital Transformation", "Skiing", "Podcasts"],
      backstory: "Thomas is an expert in digital strategy, helping organizations navigate the ever-changing landscape of technology and consumer behavior. He excels at identifying growth opportunities and developing data-driven strategies.",
      challenges: "Keeping up with rapid technological advancements",
      goals: ["Increase online revenue by 40%", "Develop a comprehensive digital roadmap"]
    },
    {
      name: "Sarah",
      title: "Data Analyst",
      age: 29,
      salary: "€72,000/year",
      image: require('../components/personas/Sarah.jpeg'),
      interests: ["Data Visualization", "Running", "Board Games"],
      backstory: "With a strong background in statistics and programming, Sarah transforms raw data into actionable insights. She's skilled at identifying patterns, building predictive models, and communicating complex findings.",
      challenges: "Ensuring data quality and integrity",
      goals: ["Automate data pipelines for real-time reporting", "Develop advanced analytics capabilities"]
    },
    {
      name: "Daniel",
      title: "Content Strategist",
      age: 32,
      salary: "€68,000/year",
      image: require('../components/personas/Daniel.jpeg'),
      interests: ["Storytelling", "Creative Writing", "Indie Films"],
      backstory: "Daniel is a skilled content strategist, crafting compelling narratives that engage audiences and drive business objectives. He excels at developing content strategies, managing editorial calendars, and optimizing content for search engines.",
      challenges: "Creating content that resonates across multiple channels",
      goals: ["Increase organic traffic by 50%", "Develop a content governance framework"]
    },
    {
      name: "Laura",
      title: "Brand Manager",
      age: 26,
      salary: "€92,000/year",
      image: require('../components/personas/Laura.jpeg'),
      interests: ["Brand Strategy", "Fashion", "Sustainable Living"],
      backstory: "Laura is a strategic brand manager, responsible for building and maintaining strong brand identities. She excels at conducting market research, developing brand positioning, and managing brand campaigns across various touchpoints.",
      challenges: "Maintaining brand consistency across multiple markets",
      goals: ["Launch a new brand identity by Q3", "Increase brand awareness by 25%"]
    },
    {
      name: "Abdel",
      title: "Small Business Owner",
      age: 45,
      salary: "€55,000/year",
      image: require('../components/personas/Abdel.jpeg'),
      interests: ["Entrepreneurship", "Gardening", "Community Service"],
      backstory: "Abdel is a passionate small business owner, running a local restaurant that serves authentic cuisine. He's driven by a desire to provide exceptional customer experiences and support his community.",
      challenges: "Managing cash flow and operational costs",
      goals: ["Expand to a second location within 2 years", "Implement sustainable business practices"]
    },
    {
      name: "Ryan",
      title: "Social Media Manager",
      age: 24,
      salary: "€65,000/year",
      image: require('../components/personas/Ryan.jpeg'),
      interests: ["Social Media Marketing", "Photography", "Fitness"],
      backstory: "Ryan is a skilled social media manager, responsible for developing and executing social media strategies for various brands. He excels at creating engaging content, analyzing social media metrics, and building online communities.",
      challenges: "Staying ahead of rapidly evolving social media trends",
      goals: ["Increase social media engagement by 40%", "Develop influencer marketing campaigns"]
    }
  ];

  return (
    <div className="personas-page">
      <div className="intro-text">
        <h2>Meet Our Growing Persona Community</h2>
        <div className="container">
          <p>Welcome to our diverse collection of research personas, each representing unique market segments and consumer behaviors. These digital participants respond to surveys and participate in focus groups within minutes, providing rapid insights for your research needs.</p>
          <p>As our community grows, each persona becomes more detailed and nuanced, learning from thousands of interactions to provide increasingly valuable feedback.</p>
        </div>
      </div>
      <div className="personas-grid">
        {personas.map((persona, index) => (
          <div
            className="persona-card"
            key={index}
            onClick={() => toggleCard(index)}
          >
            <img
              src={persona.image}
              alt={`${persona.name}`}
              className="persona-image"
            />
            <h3>{persona.name}</h3>
            <h4>{persona.title}</h4>
            <div className="persona-details">
              <p><strong>Age:</strong> {persona.age}</p>
              <p><strong>Salary:</strong> {persona.salary}</p>
            </div>
          </div>
        ))}
      </div>

      <div className={`expanded-overlay ${expandedCard !== null ? 'active' : ''}`}>
        {expandedCard !== null && (
          <div className="expanded-card" onClick={(e) => e.stopPropagation()}>
            <button
              className="close-button"
              onClick={(e) => {
                e.stopPropagation();
                setExpandedCard(null);
              }}
            >
              ×
            </button>
            <div className="container" style={{ textAlign: 'center' }}>
            <img
              src={personas[expandedCard].image}
              alt={`${personas[expandedCard].name}`}
              className="persona-image"
            />
            <h3>{personas[expandedCard].name}</h3>
            <h4>{personas[expandedCard].title}</h4>
            <div className="persona-details">
              <p><strong>Age:</strong> {personas[expandedCard].age}</p>
              <p><strong>Salary:</strong> {personas[expandedCard].salary}</p>
            </div>
            </div>

            <div className="persona-expanded">
              <h4>Interests</h4>
              <ul>
                {personas[expandedCard].interests.map((interest, i) => (
                  <li key={i}>{interest}</li>
                ))}
              </ul>
              <h4>Background</h4>
              <p>{personas[expandedCard].backstory}</p>
              <h4>Key Challenges</h4>
              <p>{personas[expandedCard].challenges}</p>
              <h4>Goals</h4>
              <ul>
                {personas[expandedCard].goals.map((goal, i) => (
                  <li key={i}>{goal}</li>
                ))}
              </ul>
            </div>
            </div>
        )}
      </div>

      <div className="hero hero-3">
        <div className="outro-text">
          <h3>Unlock Rapid Consumer Insights</h3>
          <div className="container">
            <p>Our personas enable companies to gather quick, meaningful insights through surveys and focus groups, helping inform product development, marketing strategies, and business decisions with unprecedented speed and accuracy.</p>
            <p>Whether you're testing new concepts, exploring market opportunities, or validating assumptions, our growing persona community is ready to provide valuable feedback within minutes.</p>
          </div>
        </div>

        {user ? (
          <a href="/research" className="button standout-button">Start Research</a>
        ) : (
          <a href="/login" className="button standout-button" onClick={() => { alert('You need to be logged in first.'); return false; }}>Start Research</a>
        )}
      </div>
    </div>
  );
}

export default OurPersonas;
