import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../styles/HomePage.css';
import '../index.css';

function LoginPage() {
  const [view, setView] = useState('login');
  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [resetCode, setResetCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const { 
    login, 
    signUp, 
    verifyEmail, 
    forgotPassword, 
    confirmPassword,
    resendVerificationCode,
    checkUserExists 
  } = useAuth();
  
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      await login(loginEmail, loginPassword);
      const from = location.state?.from || '/';
      navigate(from, { replace: true });
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        setMessage('Please verify your email first.');
        setView('verify');
      } else {
        setError(err.message || 'Failed to login');
      }
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    
    try {
      await forgotPassword(forgotPasswordEmail);
      setMessage('If an account exists with this email, a password reset code will be sent.');
      setView('resetPassword');
    } catch (err) {
      // Don't expose whether the user exists or not for security
      setError('Something went wrong. Please try again.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
      await confirmPassword(forgotPasswordEmail, resetCode, newPassword);
      setMessage('Password reset successful! You can now login.');
      setView('login');
      // Clear sensitive fields
      setResetCode('');
      setNewPassword('');
      setForgotPasswordEmail('');
    } catch (err) {
      setError(err.message || 'Failed to reset password');
    }
  };

  const renderForgotPassword = () => (
    <>
      <h1>Reset Password</h1>
      <form onSubmit={handleForgotPassword}>
        <div className="form-group">
          <label htmlFor="forgotPasswordEmail">Email:</label>
          <input
            type="email"
            id="forgotPasswordEmail"
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="button standout-button">
          Send Reset Code
        </button>
        <button 
          type="button"
          onClick={() => setView('login')} 
          className="button secondary-button"
        >
          Back to Login
        </button>
      </form>
    </>
  );

  const renderResetPassword = () => (
    <>
      <h1>Enter Reset Code</h1>
      <p>A reset code has been sent to: {forgotPasswordEmail}</p>
      <form onSubmit={handleResetPassword}>
        <div className="form-group">
          <label htmlFor="resetCode">Reset Code:</label>
          <input
            type="text"
            id="resetCode"
            value={resetCode}
            onChange={(e) => setResetCode(e.target.value)}
            required
          />
          <label htmlFor="newPassword">New Password:</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="button standout-button">
          Reset Password
        </button>
        <button 
          type="button"
          onClick={() => setView('forgotPassword')} 
          className="button secondary-button"
        >
          Back
        </button>
      </form>
    </>
  );

  const renderLogin = () => (
    <>
      <h1>Please Login:</h1>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label htmlFor="loginEmail">Email:</label>
          <input 
            type="email" 
            id="loginEmail" 
            value={loginEmail} 
            onChange={(e) => setLoginEmail(e.target.value)} 
            required 
          />
          <label htmlFor="loginPassword">Password:</label>
          <input 
            type="password" 
            id="loginPassword" 
            value={loginPassword} 
            onChange={(e) => setLoginPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="button standout-button">
          Login
        </button>
      </form>
      <p>
        Don't have an account?{' '}
        <button onClick={() => setView('register')} className="link-button">
          Register here
        </button>
      </p>
      <p>
        <button onClick={() => setView('forgotPassword')} className="link-button">
          Forgot Password?
        </button>
      </p>
    </>
  );

  const renderRegister = () => (
    <>
      <h1>Create Account:</h1>
      <form onSubmit={handleRegister}>
        <div className="form-group">
          <label htmlFor="firstName">First Name:</label>
          <input 
            type="text" 
            id="firstName" 
            value={firstName} 
            onChange={(e) => setFirstName(e.target.value)} 
            required 
          />
          <label htmlFor="lastName">Last Name:</label>
          <input 
            type="text" 
            id="lastName" 
            value={lastName} 
            onChange={(e) => setLastName(e.target.value)} 
            required 
          />
          <label htmlFor="loginEmail">Email:</label>
          <input 
            type="email" 
            id="loginEmail" 
            value={loginEmail} 
            onChange={(e) => setLoginEmail(e.target.value)} 
            required 
          />
          <label htmlFor="loginPassword">Password:</label>
          <input 
            type="password" 
            id="loginPassword" 
            value={loginPassword} 
            onChange={(e) => setLoginPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" className="button standout-button">
          Register
        </button>
      </form>
      <p>
        Already have an account?{' '}
        <button onClick={() => setView('login')} className="link-button">
          Login here
        </button>
      </p>
    </>
  );

  const handleRegister = async (event) => {
    event.preventDefault();
    setError('');
    
    try {
      await signUp(loginEmail, loginPassword, firstName, lastName);
      setMessage('Registration successful! Please check your email for verification code.');
      setView('verify');
    } catch (err) {
      if (err.code === 'UsernameExistsException') {
        try {
          await resendVerificationCode(loginEmail);
          setMessage('Account exists but not verified. A new verification code has been sent.');
          setView('verify');
        } catch (resendErr) {
          setError('Error resending verification code. Please try again.');
        }
      } else {
        setError(err.message || 'Failed to register');
      }
    }
  };

  const renderVerification = () => (
    <>
      <h1>Verify Email:</h1>
      <p>A verification code has been sent to: {loginEmail}</p>
      <form onSubmit={handleVerification}>
        <div className="form-group">
          <label htmlFor="verificationCode">Verification Code:</label>
          <input 
            type="text" 
            id="verificationCode" 
            value={verificationCode} 
            onChange={(e) => setVerificationCode(e.target.value)} 
            required 
          />
        </div>
        <div className="button-group">
          <button type="submit" className="button standout-button">
            Verify Email
          </button>
          <button 
            type="button" 
            onClick={handleResendCode} 
            className="button secondary-button"
          >
            Resend Code
          </button>
          <button 
            type="button" 
            onClick={() => setView('login')} 
            className="button link-button"
          >
            Back to Login
          </button>
        </div>
      </form>
    </>
  );

  const handleVerification = async (event) => {
    event.preventDefault();
    setError('');
    
    try {
      await verifyEmail(loginEmail, verificationCode);
      setMessage('Email verified successfully! You can now login.');
      setView('login');
    } catch (err) {
      setError(err.message || 'Failed to verify email');
    }
  };

  const handleResendCode = async () => {
    try {
      await resendVerificationCode(loginEmail);
      setMessage('Verification code resent! Please check your email.');
    } catch (err) {
      setError(err.message || 'Failed to resend code');
    }
  };

  return (
    <div className="login-page">
      <div className="hero hero-1 top-hero">
        <div className="container">
          {error && <div className="error-message">{error}</div>}
          {message && <div className="success-message">{message}</div>}
          
          {view === 'login' && renderLogin()}
          {view === 'register' && renderRegister()}
          {view === 'verify' && renderVerification()}
          {view === 'forgotPassword' && renderForgotPassword()}
          {view === 'resetPassword' && renderResetPassword()}
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
